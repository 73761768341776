.main-container {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  flex: 1;
}

.main-container__wrapper {
  height: 100%;
  width: 98%;
  margin: auto;
  max-width: 960px;
}
