.circle {
  border: 1px solid gray;
}

.hit {
  transition: none;
  box-shadow: inset 0px 0px 10px 5px gray;
}

.circle, .sub-circle {
  background-color: #fbeab7;
  border-radius: 5000px;
  display: flex;
  flex-flow: column;
  transition: 0.25s ease all;
}

.main-circle {
  width: 510px;
}

.next-circle {
  width: 410px;
}

.main-circle .circle {
  width: 500px;
  height: 500px;
}

.main-circle .sub-circle {
  width: 300px;
  height: 300px;
}

.next-circle .circle {
  width: 400px;
  height: 400px;
}

.next-circle .sub-circle {
  width: 200px;
  height: 200px;
}
