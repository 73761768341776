:root {
  --color-font: #233140;
  --color-background: white;
  --color-primary: #540ead;
  --color-primary-light: rgba(84, 14, 173, 0.7);
  --color-primary-lighter: rgba(84, 14, 173, 0.5);
  --color-primary-lightest: rgba(84, 14, 173, 0.25);
  --color-primary-ultra-light: rgba(84, 14, 173, 0.05);

  --color-level-0: #eee;
  --color-level-1: #ee0000;
  --color-level-2: #ff9000;
  --color-level-3: #FDF400;
  --color-level-4: #82C800;
  --color-level-5: #009939;

  --margin-xs: 0.25em;
  --margin-s: 0.5em;
  --margin-m: 1em;
  --margin-l: 1.5em;
}
