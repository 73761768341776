@import "common/styles/constants.css";
@import "common/styles/margin.css";

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Jura', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-font);
  background: var(--color-background);
  outline: none;
}

header {
  outline: none;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.row {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.col {
  display: flex;
  flex-flow: column;
}

.wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between !important;
}

.space-evenly {
  justify-content: space-evenly;
}

.space-around {
  justify-content: space-around;
}

.flex-end {
  justify-content: flex-end;
}

.clickable {
  cursor: pointer;
}

.center {
  text-align: center;
}

.justify {
  text-align: justify;
}

.bold {
  font-weight: bold;
}

.full {
  width: 100%;
}
